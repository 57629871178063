import React, { useState } from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { RxCrossCircled } from "react-icons/rx";
import { GrInProgress } from "react-icons/gr";
import { apiGenerator } from "../../../util/functions";
import { CheckCircleOutlined } from "@ant-design/icons";
import useHttp from "../../../hooks/use-http";
import CRUDComponent from "../../../component/common/CRUD-Component";
import { useNavigate } from "react-router-dom";

const RequestList = ({ accessPermission }) => {
  const [refresh, setRefresh] = useState(false);
  const API = useHttp();
  const navigate = useNavigate();
  const requestHandler = (data) => {
    const { status, dataId } = data;

    const payload = {
      status,
    };
    const REQUEST_API = apiGenerator(
      CONSTANTS.API?.Role_Management?.adminApproval,
      {
        dataId,
      }
    );
    API.sendRequest(
      REQUEST_API,
      () => {
        setRefresh((prev) => !prev);
      },
      payload
    );
  };
  return (
    <CRUDComponent
      reload={refresh}
      title="User"
      accessPermission={accessPermission}
      GET={{
        API: CONSTANTS.API.trajection.getAll,
        extraQuery: { status: "PendingFromAdminSide" },
        DataModifier: (res, API, Setrefresh) => {
          return res?.map((data) => {
            console.log(data);
            return {
              ...data,
              no: `U${data?.id.toString().padStart(7, "0")}`,
              view: {
                id: data?.user?.id,
                onClick: (id) => {
                  navigate(`/app/user/${id}`);
                },
              },
              name: data?.user?.name,
              image: data?.user?.image,
              userImage: { image: data?.user?.image, name: data?.user?.name },
              request: {
                id: data?.id,
                onAccept: () => {
                  const load = {
                    dataId: data?.id,
                    status: "Success",
                  };
                  requestHandler(load);
                },
                onReject: () => {
                  const load = {
                    dataId: data?.id,
                    status: "Fail",
                  };
                  requestHandler(load);
                },
              },
              identify: (
                <>
                  {data?.user?.isGovermentIdVerified === "Verified" ? (
                    <CheckCircleOutlined className="text-2xl text-[#007BFF]" />
                  ) : data?.user?.isGovermentIdVerified === "Failed" ? (
                    <RxCrossCircled className="text-2xl text-red-500" />
                  ) : (
                    <GrInProgress className="text-2xl text-slate-500" />
                  )}
                </>
              ),
            };
          });
        },
        column: CONSTANTS.TABLE.WITHDRAWAL_REQUEST,
      }}
      //   isSearch
    />
  );
};

export default RequestList;
