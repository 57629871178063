import { Card, Row, Tabs } from "antd";
import React from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import Heading from "../../../component/common/Heading";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { convertUTCToLocal, PERMISSION } from "../../../util/functions";
import { useNavigate } from "react-router-dom";
import { userExportData } from "../../../util/exportData";
import { RxCrossCircled } from "react-icons/rx";
import { GrInProgress } from "react-icons/gr";

const User = ({ admindetails, permissionId }) => {
  const navigate = useNavigate();
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];

  const UserList = () => (
    <CRUDComponent
      title="User"
      exportData={userExportData}
      accessPermission={accessPermission}
      GET={{
        API: CONSTANTS.API.user.getAll,
        DataModifier: (res, API, Setrefresh) => {
          return res?.map((data) => {
            return {
              ...data,
              no: `U${data?.id.toString().padStart(7, "0")}`,
              view: {
                id: data?.id,
                onClick: (id) => {
                  navigate(`/app/user/${id}`);
                },
              },
              userImage: { image: data?.image, name: data?.name },
              city: data?.city,
              // dob: convertUTCToLocal(data?.DOB, "DD-MM-YYYY"),
              toggle: {
                checked: !data?.isBlocked,
                id: data?.id,
                isDisable: PERMISSION[accessPermission] === "READ",
                onClick: (id, val) => {
                  const UpdateAPIEnd = {
                    ...CONSTANTS?.API.user.update,
                  };
                  UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                  API.sendRequest(
                    UpdateAPIEnd,
                    () => {
                      Setrefresh((prev) => !prev);
                    },
                    { isBlocked: val },
                    `User ${val ? "Blocked" : "Unblock"} Successfully`
                  );
                },
              },
              identify: (
                <>
                  {data?.isGovermentIdVerified === "Verified" ? (
                    <CheckCircleOutlined className="text-2xl text-[#007BFF]" />
                  ) : data?.isGovermentIdVerified === "Failed" ? (
                    <RxCrossCircled className="text-2xl text-red-500" />
                  ) : (
                    <GrInProgress className="text-2xl text-slate-500" />
                  )}
                </>
              ),
              isAffiliateD: data?.isAffiliateEnable ? "Yes" : "No",
              isBlockedD: data?.isBlocked ? "Yes" : "No",
              isIdentifyD: data?.isGovermentIdVerified,
              affiliate: {
                checked: !data?.isAffiliateEnable,
                id: data?.id,
                isDisable: PERMISSION[accessPermission] === "READ",
                onClick: (id, val) => {
                  const UpdateAPIEnd = {
                    ...CONSTANTS?.API.user.update,
                  };
                  UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                  API.sendRequest(
                    UpdateAPIEnd,
                    () => {
                      Setrefresh((prev) => !prev);
                    },
                    { isAffiliateEnable: val },
                    `Affiliate ${val ? "Activate" : "Deactivate"} Successfully`
                  );
                },
              },
            };
          });
        },
        column: CONSTANTS.TABLE.USERS,
      }}
      isSearch
      UPDATE={{
        API: CONSTANTS.API.user.update,
        isFormData: true,
        message: "Edit User successfully",
        modaltitle: "Edit User",
        modalFields: CONSTANTS.FORM_FIELD.EDIT_USER_MODAL,
        // payloadModifier: (res) => res,
      }}
    />
  );

  const DeletedUser = () => (
    <CRUDComponent
      title="User"
      // exportData={userExportData}
      accessPermission={accessPermission}
      GET={{
        API: CONSTANTS.API.user.deletedUserList,
        DataModifier: (res, API, Setrefresh) => {
          return res?.map((data) => {
            return {
              ...data,
              no: `U${data?.id.toString().padStart(7, "0")}`,
              view: {
                id: data?.id,
                onClick: (id) => {
                  navigate(`/app/user/${id}`);
                },
              },
              userImage: { image: data?.image, name: data?.name },
              city: data?.city,
              // dob: convertUTCToLocal(data?.DOB, "DD-MM-YYYY"),
              identify: (
                <>
                  {data?.isGovermentIdVerified === "Verified" ? (
                    <CheckCircleOutlined className="text-2xl text-[#007BFF]" />
                  ) : data?.isGovermentIdVerified === "Failed" ? (
                    <RxCrossCircled className="text-2xl text-red-500" />
                  ) : (
                    <GrInProgress className="text-2xl text-slate-500" />
                  )}
                </>
              ),
              isIdentifyD: data?.isGovermentIdVerified,
            };
          });
        },
        column: CONSTANTS.TABLE.USERS?.filter(
          (field) =>
            !["editTableRow", "toggle", "affiliate"].includes(field?.dataIndex)
        ),
      }}
      isSearch
    />
  );

  const menus = {
    list: <UserList />,
    deletedList: <DeletedUser />,
  };

  return (
    <>
      <div>
        <Card className="my-5">
          <Heading>User List</Heading>
          <Row>
            <Tabs
              className="p-0 mt-5"
              size="middle"
              type="card"
              items={CONSTANTS.TAB_MENU.USER_LISR.map((ele) => {
                return {
                  label: `${ele?.Label}`,
                  key: `${ele?.id}`,
                  children: menus[ele?.id],
                };
              })}
            />
          </Row>
        </Card>
      </div>
    </>
  );
};

export default User;
