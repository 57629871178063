import { Button, Col, Form, Input, InputNumber, Row, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import useHttp from '../../../../hooks/use-http'
import CONSTANTS from '../../../../util/constant/CONSTANTS'
import moment from 'moment'
import Heading from '../../../../component/common/Heading'
import { PERMISSION } from '../../../../util/functions'
import TextArea from 'antd/es/input/TextArea'

const General = ({ accessPermission }) => {
    const [appConfig, setAppConfig] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [hideMessageField, setHideMessageField] = useState(false)
    const api = useHttp()
    const [form] = Form.useForm();
    form.setFieldsValue(appConfig)

    // console.log(Object.values(PERMISSION).includes(accessPermission));
    const submitHandler = (value) => {
        const payload = {
            androidVersion: value?.androidVersion,
            iosVersion: value?.iosVersion,
            underMaintenance: value?.underMaintenance,
            forceUpdate: value?.forceUpdate,
            softUpdate: value?.softUpdate,
            earningAmountOnInvite: value?.earningAmountOnInvite,
            userInitialFreeSpin: value?.userInitialFreeSpin,
            newUserInitialeaningAmount: value?.newUserInitialeaningAmount,
            moneyWithdrawalThroesholdLimit: value?.moneyWithdrawalThroesholdLimit,
            moneyWithdrawalThresholdUpperLimit: value?.moneyWithdrawalThresholdUpperLimit,
            isMoneyWithdrawalEnable: value?.isMoneyWithdrawalEnable,
            isEmulatorAllow: value?.isEmulatorAllow,
            isCityAllow: value?.isCityAllow,
            isRootDeviceAllow: value?.isRootDeviceAllow,
            moneyWithdrawalPendingMessage: value?.isMoneyWithdrawalEnable ? "" : value?.moneyWithdrawalPendingMessage ?? ""
        }
        // console.log(payload);


        const SET_APP_CONFIG_API = { ...CONSTANTS.API.setting.general.update }
        SET_APP_CONFIG_API.endpoint = SET_APP_CONFIG_API.endpoint.replace(':id', appConfig?.id)
        api.sendRequest(SET_APP_CONFIG_API, () => {
            setRefresh(prev => !prev)
        }, payload, 'Configuration Upadated Successfully!!!')
    }

    useEffect(() => {
        const GET_CONFIG_DATA_API = { ...CONSTANTS.API.setting.general.getAll }

        api.sendRequest(GET_CONFIG_DATA_API, (res) => {
            setAppConfig(res?.data?.rows[0]);
            setHideMessageField(res?.data?.rows[0]?.isMoneyWithdrawalEnable)
        })
    }, [refresh])
    return (
        <>
            <div className="my-5 p-6">
                <Heading>
                    App Config
                </Heading>
                <Form disabled={PERMISSION['READ'] === accessPermission} className='my-5' onFinish={submitHandler} form={form}>


                    {/* Maintanance */}
                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                                App Under Maintanance
                            </p></Col>
                        <Col>
                            <Form.Item name='underMaintenance' id="underMaintenance" >
                                <Switch
                                    className="mt-2"
                                    checked={appConfig.underMaintenance}
                                    onChange={(value) => {
                                        setAppConfig(prev => {
                                            return {
                                                ...prev,
                                                underMaintenance: value
                                            }
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Soft Update */}
                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                                App Soft Update
                            </p></Col>
                        <Col>
                            <Form.Item name='softUpdate' id="softUpdate" >
                                <Switch
                                    className="mt-2"
                                    checked={appConfig.softUpdate}
                                    onChange={(value) => {
                                        setAppConfig(prev => {
                                            return {
                                                ...prev,
                                                softUpdate: value
                                            }
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Force Update */}
                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                                App Force Update
                            </p></Col>
                        <Col>
                            <Form.Item name='forceUpdate' id="forceUpdate" >
                                <Switch
                                    className="mt-2"
                                    checked={appConfig.forceUpdate}
                                    onChange={(value) => {
                                        setAppConfig(prev => {
                                            return {
                                                ...prev,
                                                forceUpdate: value
                                            }
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                            Withdrawal
                            </p></Col>
                        <Col>
                            <Form.Item name='isMoneyWithdrawalEnable' id="isMoneyWithdrawalEnable" >
                                <Switch
                                    className="mt-2"
                                    checked={appConfig.isMoneyWithdrawalEnable}
                                    onChange={(value) => {
                                        setAppConfig(prev => {
                                            return {
                                                ...prev,
                                                isMoneyWithdrawalEnable: value
                                            }
                                        })
                                        setHideMessageField(value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {!hideMessageField && <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                            Withdrawal Pending Message
                            </p></Col>
                        <Col>
                            <Form.Item name='moneyWithdrawalPendingMessage' id="moneyWithdrawalPendingMessage"  >
                                <TextArea onChange={(e) => {
                                    // console.log(+e.target.value);
                                    setAppConfig(prev => {
                                        return {
                                            ...prev,
                                            moneyWithdrawalPendingMessage: e.target.value
                                        }
                                    })
                                }} />

                            </Form.Item>
                        </Col>
                    </Row>}    
                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                            Emulator allowed
                            </p></Col>
                        <Col>
                            <Form.Item name='isEmulatorAllow' id="isEmulatorAllow" >
                                <Switch
                                    className="mt-2"
                                    checked={appConfig?.isEmulatorAllow}
                                    onChange={(value) => {
                                        setAppConfig(prev => {
                                            return {
                                                ...prev,
                                                isEmulatorAllow: value
                                            }
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                            City allowed
                            </p></Col>
                        <Col>
                            <Form.Item name='isCityAllow' id="isCityAllow" >
                                <Switch
                                    className="mt-2"
                                    checked={appConfig?.isCityAllow}
                                    onChange={(value) => {
                                        setAppConfig(prev => {
                                            return {
                                                ...prev,
                                                isCityAllow: value
                                            }
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>  
                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                            Root Device allowed
                            </p></Col>
                        <Col>
                            <Form.Item name='isRootDeviceAllow' id="isRootDeviceAllow" >
                                <Switch
                                    className="mt-2"
                                    checked={appConfig?.isRootDeviceAllow}
                                    onChange={(value) => {
                                        setAppConfig(prev => {
                                            return {
                                                ...prev,
                                                isRootDeviceAllow: value
                                            }
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>    
                    {/* Android & IOSVersion */}
                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                                Android Version
                            </p></Col>
                        <Col>
                            <Form.Item name='androidVersion' id="androidVersion"  >
                                <Input type="text" onChange={(e) => {
                                    // console.log(+e.target.value);
                                    setAppConfig(prev => {
                                        return {
                                            ...prev,
                                            androidVersion: e.target.value
                                        }
                                    })
                                }} />

                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                                IOS Version
                            </p></Col>
                        <Col>
                            <Form.Item name='iosVersion' id="iosVersion"  >
                                <Input type="text" onChange={(e) => {
                                    // console.log(+e.target.value);
                                    setAppConfig(prev => {
                                        return {
                                            ...prev,
                                            iosVersion: e.target.value
                                        }
                                    })
                                }} />

                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Invite Amount */}
                    <Row>
                        <Col span={8}>
                            <p className="text-xl  mt-1">
                                Earning Amount on the Invite
                            </p></Col>
                        <Col>
                            <Form.Item name='earningAmountOnInvite' id="earningAmountOnInvite"  >
                                <InputNumber className='w-[100%]' onChange={(e) => {
                                    // console.log(+e.target.value);
                                    setAppConfig(prev => {
                                        return {
                                            ...prev,
                                            earningAmountOnInvite: +e
                                        }
                                    })
                                }} />

                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Initial Free Spin */}
                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                                New User Initial Free Spin
                            </p></Col>
                        <Col>
                            <Form.Item name='userInitialFreeSpin' id="userInitialFreeSpin"  >
                                <InputNumber className='w-[100%]' onChange={(e) => {
                                    // console.log(+e.target.value);
                                    setAppConfig(prev => {
                                        return {
                                            ...prev,
                                            userInitialFreeSpin: +e
                                        }
                                    })
                                }} />

                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Initial Earning Amount */}
                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                                New User Initial Earning Amount
                            </p></Col>
                        <Col>
                            <Form.Item name='newUserInitialeaningAmount' id="newUserInitialeaningAmount"  >
                                <InputNumber className='w-[100%]' onChange={(e) => {
                                    // console.log(+e.target.value);
                                    setAppConfig(prev => {
                                        return {
                                            ...prev,
                                            newUserInitialeaningAmount: +e
                                        }
                                    })
                                }} />

                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Initial Earning Amount */}
                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                                Money Withdrawal Threshold Limit <span className='text-base'>(Minimum)</span>
                            </p></Col>
                        <Col>
                            <Form.Item name='moneyWithdrawalThroesholdLimit' id="moneyWithdrawalThroesholdLimit"  >
                                <InputNumber className='w-[100%]' onChange={(e) => {
                                    console.log(+e);
                                    setAppConfig(prev => {
                                        return {
                                            ...prev,
                                            moneyWithdrawalThroesholdLimit: +e
                                        }
                                    })
                                }} />

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <p className="text-xl mt-1">
                                Auto Withdrawal Threshold Limit <span className='text-base'>(Maximum)</span>
                            </p></Col>
                        <Col>
                            <Form.Item name='moneyWithdrawalThresholdUpperLimit' id="moneyWithdrawalThresholdUpperLimit"  >
                                <InputNumber className='w-[100%]' onChange={(e) => {
                                    setAppConfig(prev => {
                                        return {
                                            ...prev,
                                            moneyWithdrawalThresholdUpperLimit: +e
                                        }
                                    })
                                }} />

                            </Form.Item>
                        </Col>
                    </Row>

                    {PERMISSION[accessPermission] !== 'READ' && <div>
                        <p className='mt-3'>
                            Last Updated on {moment.utc(appConfig?.updatedAt).local().format('DD MMM, YYYY')} {moment.utc(appConfig?.updatedAt).local().format('hh:mm A')}
                        </p>

                        <Form.Item

                        >
                            <Button className='mt-4' type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </div>}
                </Form>

            </div>
        </>
    )
}

export default General