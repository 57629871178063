import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Input,
  Pagination,
  Popconfirm,
  QRCode,
  Result,
  Row,
} from "antd";
import moment from "moment";
import PropTypes from "prop-types";
// import PageHeader from "../../../../component/common/page-Header";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../util/constant/CONSTANTS";
import ModalFormCreator from "./ModalFormCreator";
import CRUDTable from "./CRUD-Table";
import CustomSearchBar from "./Custom-search";
import ReactToPrint from "react-to-print";
import { PERMISSION } from "../../util/functions";
import { CSVLink } from "react-csv";
// import CustomTable from "../../../../component/common/Custom-Table";
// import CONSTANTS from "../../../../util/constant/CONSTANTS";
// import useHttp from "../../../../hooks/use-http";
// import ModalFormCreator from "../../../../component/common/ModalFormCreator";
const CRUDComponent = (props) => {
  let {
    GET,
    CREATE,
    UPDATE,
    DELETE,
    isSearch = false,
    QRdownload = false,
    showPagination = true,
    reload = null,
    AddOnComponent = null,
    selectionOff = false,
    FILTERSORTKEY = {},
    title = "",
    exportData,
    accessPermission,
  } = props;
  const [data, setData] = useState([]);
  const [Allfilter, setAllFilter] = useState(null);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [createOpen, setCreateOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(null);
  const [formData, setFormData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const componentRef = useRef();
  const [CSV, setCSV] = useState([]);
  const API = useHttp();

  if (PERMISSION["READ"] === accessPermission) {
    GET = {
      ...GET,
      column: GET?.column?.filter(
        (ele) =>
          ele?.dataIndex !== "editTableRow" &&
          ele?.dataIndex !== "deleteTableRow"
      ),
    };
  } else if (PERMISSION["WRITE_WITH_OUT_DELETE"] === accessPermission) {
    GET = {
      ...GET,
      column: GET?.column?.filter((ele) => ele?.dataIndex !== "deleteTableRow"),
    };
  }
  // console.log(GET);

  useEffect(() => {
    let QuaryParams = {
      limit: pagination.pageSize,
      page: pagination.current,
    };
    if (GET?.extraQuery) {
      QuaryParams = { ...QuaryParams, ...GET?.extraQuery };
    }
    if (Allfilter?.sort) {
      const sorter = { ...Allfilter?.sort };
      sorter.sortBy = FILTERSORTKEY?.[sorter?.sortBy] || sorter?.sortBy;
      QuaryParams = { ...QuaryParams, ...sorter };
    }
    if (Allfilter?.filter) {
      Allfilter?.filter?.map((el) => {
        QuaryParams = {
          ...QuaryParams,
          [`autogenerate-mul-array-${FILTERSORTKEY?.[el[0]] || el[0]}`]: el[1],
        };
      });
    }
    if (search?.trim().length) {
      QuaryParams = { ...QuaryParams, search: search };
    }
    GET &&
      GET?.API &&
      API.sendRequest(
        GET?.API,
        (res) => {
          setPagination((prevPagination) => ({
            ...prevPagination,
            total: res?.data?.count ?? 0,
          }));
          let ResultData =
            res?.data?.data?.rows || res?.data?.rows || res?.data;
          ResultData = ResultData?.map((el, i) => {
            const SingleRow = {
              ...el,
              key: el.id,
              no: `${
                (pagination.current - 1) * pagination.pageSize + i + 1
              }`.padStart(6, "0"),
            };
            if (
              DELETE &&
              DELETE?.API &&
              GET?.column?.findIndex(
                (el) => el?.dataIndex === "deleteTableRow"
              ) !== -1
            ) {
              SingleRow.deleteTableRow = {
                id: el.id,
                key: el.id,
                onClick: (key) => {
                  const DeleteAPITableRow = { ...DELETE?.API };
                  DeleteAPITableRow.endpoint = `${DeleteAPITableRow.endpoint}${key}`;
                  API.sendRequest(
                    DeleteAPITableRow,
                    (res) => {
                      // console.log(res);
                      setData((prev) =>
                        prev.filter((item) => item.key !== key)
                      );
                    },
                    "",
                    DELETE?.message
                  );
                },
              };
            }
            if (
              UPDATE &&
              UPDATE?.API &&
              UPDATE?.modalFields &&
              GET?.column?.findIndex(
                (el) => el?.dataIndex === "editTableRow"
              ) !== -1
            ) {
              SingleRow.editTableRow = {
                id: el.id,
                key: el.id,
                onClick: () => {
                  setUpdateOpen({ ...SingleRow });
                  setFormData({ ...SingleRow });
                },
              };
            }
            return { ...SingleRow };
          });
          if (GET?.DataModifier) {
            setData(GET?.DataModifier(ResultData, API, setRefresh));
          } else {
            setData(ResultData);
          }
        },
        QuaryParams
      );
  }, [
    refresh,
    pagination.current,
    pagination.pageSize,
    Allfilter,
    search,
    GET?.extraQuery,
    reload,
  ]);
  //   const CSVData = [];
  //   CSVData[0] = CONSTANTS.TABLE.SETTING_ROUTINE_CHECKUP.map((el) => el.title);
  //   data.map((item, index) => {
  //     CSVData[index + 1] = CONSTANTS.TABLE.SETTING_ROUTINE_CHECKUP.map(
  //       (el) => item[el.dataIndex]
  //     );
  //     return 0;
  //   });

  const onCreate = (value, clear) => {
    if (CREATE && CREATE?.API && CREATE?.modalFields) {
      let payload = payloadGenerator(
        value,
        CREATE?.modalFields,
        CREATE?.isFormData
      );

      if (CREATE?.payloadModifier) {
        payload = CREATE?.payloadModifier(payload);
      }

      API.sendRequest(
        CREATE?.API,
        () => {
          setRefresh((prev) => !prev);
          setCreateOpen(false);
          clear();
        },
        payload,
        CREATE?.message
      );
    }
  };
  const onUpdate = (value, clear) => {
    if (UPDATE && UPDATE?.API && UPDATE?.modalFields) {
      let payload = payloadGenerator(
        value,
        UPDATE?.modalFields,
        UPDATE?.isFormData
      );

      if (UPDATE?.payloadModifier) {
        payload = UPDATE?.payloadModifier(payload, formData);
      }

      const UpdateAPIEnd = { ...UPDATE?.API };
      UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${updateOpen?.id}`;
      API.sendRequest(
        UpdateAPIEnd,
        () => {
          setUpdateOpen(null);
          setFormData(null);
          setRefresh((prev) => !prev);
          clear();
        },
        payload,
        UPDATE?.message
      );
    }
  };

  return (
    <Row className="mt-6" gutter={[16, 16]}>
      {CREATE && CREATE?.API && CREATE?.modalFields && (
        <ModalFormCreator
          loading={API.isLoading}
          open={createOpen}
          onCreate={onCreate}
          onCancel={() => {
            setCreateOpen(false);
          }}
          menuFields={CREATE?.modalFields}
          formData={{}}
          name={CREATE?.modaltitle || `Add `}
          SubmitName={"Submit"}
        />
      )}
      {UPDATE && UPDATE?.API && UPDATE?.modalFields && (
        <ModalFormCreator
          loading={API.isLoading}
          open={updateOpen !== null}
          onCreate={onUpdate}
          onCancel={() => {
            setUpdateOpen(null);
            setFormData(null);
          }}
          menuFields={UPDATE?.modalFields}
          formData={formData}
          name={UPDATE?.modaltitle || "Edit"}
          SubmitName={"Update "}
        />
      )}
      {PERMISSION[accessPermission] !== "READ" && CREATE && CREATE?.API && (
        <Col span={24}>
          <Button
            type="primary"
            loading={API.isLoading}
            onClick={() => {
              setCreateOpen(true);
            }}
          >
            Add
          </Button>
        </Col>
      )}
      {GET?.column?.length && (
        <>
          {isSearch && (
            <Col span={24} sm={24} md={20} lg={12} xl={12} xxl={8}>
              <CustomSearchBar
                setKeyword={(v) => setSearch(v)}
                isSearch={isSearch}
              />
            </Col>
          )}
          {exportData && (
            <Col span={24} sm={24} md={4} lg={12} xl={12} xxl={16}>
              <CSVLink filename={`${title} Table.csv`} data={CSV}>
                <Button
                  className="float-right"
                  type="primary"
                  ghost
                  onClick={() => {}}
                  // {...props.ButtonDefault}
                >
                  Export CSV
                </Button>
              </CSVLink>
            </Col>
          )}

          {QRdownload && (
            <Col span={24} sm={24} md={4} lg={12} xl={10} xxl={8}>
              <ReactToPrint
                trigger={() => (
                  <Button
                    onClick={() => {}}
                    className="float-right"
                    type="primary"
                  >
                    Download All QRCode
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Col>
          )}

          {/* QR Code */}
          <div className="hidden">
            <div
              id="myqrcode"
              className="flex flex-wrap gap-10"
              ref={componentRef}
            >
              {data?.map((el) => {
                return (
                  <div className="flex flex-col ml-5 my-5">
                    <div>
                      <QRCode
                        // value={el?.deviceTypeId !== 1 ? JSON.stringify({
                        //   uniqueId: el?.uniqueId?.toString(),
                        //   simId: el?.simId?.toString(),
                        // }) : JSON.stringify({
                        //   uniqueId: el?.uniqueId?.toString(),

                        // })}
                        value={JSON.stringify({
                          uniqueId: el?.uniqueId?.toString(),
                          simId: el?.simId?.toString(),
                        })}
                        bgColor="#fff"
                        style={{
                          marginBottom: 16,
                        }}
                      />
                    </div>
                    <div>
                      <Input
                        placeholder="-"
                        disabled
                        className="text-center w-[82%] font-medium"
                        maxLength={60}
                        value={el?.no?.toString()}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {AddOnComponent}
          <Col span={24}>
            <CRUDTable
              exportData={exportData}
              setCSV={setCSV}
              dataSource={data}
              isLoading={API.isLoading}
              columns={GET?.column}
              selectionOff={selectionOff}
              //   DeleteSelectedRow
              //   APIendpoint="checkupDelete"
              //   onConfirm={() => {
              //     setRefresh((prev) => !prev);
              //   }}
              setChanges={(v) => {
                setAllFilter(v);
                setPagination((prev) => ({
                  ...prev,
                  current: 1,
                }));
              }}
            />
          </Col>
          {showPagination ? <Col span={24} className="mb-4">
            <Pagination
              current={pagination?.current}
              pageSize={pagination?.pageSize}
              total={pagination?.total}
              showSizeChanger
              onChange={(page, pageSize) => {
                setPagination((prev) => ({ ...prev, pageSize, current: page }));
              }}
            />
          </Col>: ""}
        </>
      )}
    </Row>
  );
};
CRUDComponent.propTypes = {
  GET: PropTypes.shape({
    API: PropTypes.shape({
      type: PropTypes.string,
      endpoint: PropTypes.string,
    }),
    extraQuery: PropTypes.object,
    DataModifier: PropTypes.func,
    column: PropTypes.array,
  }),
  CREATE: PropTypes.shape({
    API: PropTypes.shape({
      type: PropTypes.string,
      endpoint: PropTypes.string,
    }),
    payloadModifier: PropTypes.func,
    modalFields: PropTypes.array,
    modaltitle: PropTypes.string,
    isFormData: PropTypes.bool,
    message: PropTypes.string,
  }),
  UPDATE: PropTypes.shape({
    API: PropTypes.shape({
      type: PropTypes.string,
      endpoint: PropTypes.string,
    }),
    payloadModifier: PropTypes.func,
    modalFields: PropTypes.array,
    modaltitle: PropTypes.string,
    isFormData: PropTypes.bool,
    message: PropTypes.string,
  }),

  DELETE: PropTypes.shape({
    API: PropTypes.shape({
      type: PropTypes.string,
      endpoint: PropTypes.string,
    }),
    message: PropTypes.string,
  }),
  isSearch: PropTypes.bool,
  accessPermission: PropTypes.number,
  QRdownload: PropTypes.bool,
  selectionOff: PropTypes.bool,
  FILTERSORTKEY: PropTypes.object,
};
export default CRUDComponent;

export const payloadGenerator = (value, fields, isFormData) => {
  let rawPayload = {};
  const formPayload = new FormData();
  if (isFormData) {
    fields?.forEach((ele) => {
      // console.log(ele.id);
      if (
        ele.type !== "file" &&
        ele.type !== "date" &&
        ele.type !== "time" &&
        ele.type !== "multifield" &&
        ele.type !== "extraMultiSingle" &&
        ele.type !== "number" &&
        ele.id !== "completionAmount" &&
        ele.type !== "image-crop-upload" &&
        ele.id !== "completionSpin"
      ) {
        (value[ele.id] || typeof value[ele.id] === "boolean") &&
          formPayload.append(ele.id, value[ele.id]);
      }
      if (ele?.type === "time") {
        formPayload.append(
          ele.id,
          moment.utc(value[ele?.id]?.$d, "HH:mm:ss").format("HH:mm:ss")
        );
      }
      if (
        ["completionAmount", "completionSpin"].includes(ele?.id) &&
        !value[ele?.id]
      ) {
        formPayload.append(ele.id, "");
      }
      if (ele.type === "file" || ele.type === "image-crop-upload") {
        value[ele.id] &&
          formPayload.append(ele.id, value[ele.id][0].originFileObj);
      }
      if (ele.type === "multifield" || ele.type === "extraMultiSingle") {
        if (ele?.handler) {
          value[ele.id] &&
            formPayload.append(ele.id, ele?.handler(value[ele.id]));
        } else {
          value[ele.id] &&
            formPayload.append(ele.id, JSON.stringify(value[ele.id]));
        }
      }

      if (ele.type === "number") {
        value[ele.id] && formPayload.append(ele.id, +value[ele.id]);
      }
      if (ele.type === "date") {
        // if (dateTime) {
        const dateTimeValue = `${moment(value[ele.id].$d).format(
          "YYYY-MM-DD"
        )} ${moment(value[ele.id].$d, "HH:mm:ss").utc().format("HH:mm:ss")}`;

        value[ele.id] && formPayload.append(ele.id, dateTimeValue);
      }
    });
  } else {
    fields.forEach((ele) => {
      if (ele?.type === "date") {
        rawPayload = {
          ...rawPayload,
          [ele?.id]: moment(value[ele?.id]?.$d, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          ),
        };
      }
      if (ele?.type === "time") {
        rawPayload = {
          ...rawPayload,
          [ele?.id]: moment(value[ele?.id]?.$d, "HH:mm:ss").format("HH:mm:ss"),
        };
      }
    });
    rawPayload = { ...value, ...rawPayload };
  }

  return isFormData ? formPayload : rawPayload;
};
