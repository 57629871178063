import { Card, Row, Tabs } from "antd";
import React, { useState } from "react";
import Heading from "../../../component/common/Heading";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import RequestList from "./Request";
import History from "./History";
// Update

function WithdrawalRequest({ admindetails, permissionId }) {
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];

  const menus = {
    request: <RequestList accessPermission={accessPermission} />,
    history: <History accessPermission={accessPermission} />,
  };

  return (
    <>
      <div>
        <Card className="my-5">
          <Heading>Withdrawal Request</Heading>
          <Row>
            <Tabs
              className="p-0 mt-5"
              size="middle"
              type="card"
              items={CONSTANTS.TAB_MENU.WITHDRAWAL.map((ele) => {
                return {
                  label: `${ele.Label}`,
                  key: `${ele.id}`,
                  children: menus[ele?.id],
                };
              })}
            />
          </Row>
        </Card>
      </div>
    </>
  );
}

export default WithdrawalRequest;
