import React from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import CRUDComponent from "../../../component/common/CRUD-Component";
import { useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import { GrInProgress } from "react-icons/gr";

const History = ({ accessPermission }) => {
  const navigate = useNavigate();
  return (
    <CRUDComponent
      title="User"
      accessPermission={accessPermission}
      GET={{
        API: CONSTANTS.API.trajection.getAll,
        extraQuery: { mode: "Withdrawal", withdrawHistory: true },
        DataModifier: (res, API, Setrefresh) => {
          return res?.map((data) => {
            console.log(data);
            return {
              ...data,
              no: `U${data?.id.toString().padStart(7, "0")}`,
              view: {
                id: data?.user?.id,
                onClick: (id) => {
                  navigate(`/app/user/${id}`);
                },
              },
              name: data?.user?.name,
              image: data?.user?.image,
              userImage: { image: data?.user?.image, name: data?.user?.name },
              identify: (
                <>
                  {data?.user?.isGovermentIdVerified === "Verified" ? (
                    <CheckCircleOutlined className="text-2xl text-[#007BFF]" />
                  ) : data?.user?.isGovermentIdVerified === "Failed" ? (
                    <RxCrossCircled className="text-2xl text-red-500" />
                  ) : (
                    <GrInProgress className="text-2xl text-slate-500" />
                  )}
                </>
              ),
              statusD: (
                <>
                  {data?.status === "Success" ? (
                    <span className=" text-green-600">Success</span>
                  ) : data?.status === "Fail" ? (
                    <span className=" text-red-500">Fail</span>
                  ) : (
                    ""
                  )}
                </>
              ),
            };
          });
        },
        column: CONSTANTS.TABLE.WITHDRAWAL_HISTORY,
      }}
      //   isSearch
    />
  );
};

export default History;
